import React, { useRef, useState,useEffect} from 'react'
import Header from './Header'
import styled from 'styled-components'
import { Dimmer, Loader } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { getAgreementDetail } from '../../../actions/agreementActions'
import AgreementContent from './AgreementContent'
import TabDetailAgreements from './TabDetailAgreements'
import { UseWindowDimensions } from '../../../utils/UseWindowsDimentions'
import { useFilterPosition } from '../../../helpers/globalHelpers'
import { CollapsableDetailAgreement } from './CollapsableDetailAgreement'

const ContainerScroll = styled.div`
  width: 100%;
  height: 89.3vh;
  overflow-y: scroll;
  overflow-x: hidden;

  @media only screen and (min-width: 1330px) and (max-width: 1500px)  {
   width: 96.5%;
  }

  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
`

const AgreementDetail = (props) => {
  const dispatch = useDispatch()
  const { id } = props.match.params
  const [loader, setLoader] = useState(true)
  const windowDimensions = UseWindowDimensions();
  const filterRef = useRef(null);
  const containerRef = useRef(null);
  const { role } = useSelector((customState) => customState.authReducer.user)
  const filterPosition = useFilterPosition(filterRef, containerRef);


  useEffect(() => {
    dispatch(getAgreementDetail(id)).then(() => setLoader(false))
  }, [id, dispatch])

  return (
    <>
      <Dimmer active={loader} inverted>
        <Loader size='medium'>Cargando...</Loader>
      </Dimmer>
      <ContainerScroll ref={containerRef}>
        <Header />
        {windowDimensions.width > 767 ?
          <>
            <AgreementContent />
            <div ref={filterRef}>
              {role === '61b36e2fc34e22351bf8cfb4' && <TabDetailAgreements isResponsive={false} filterPosition={filterPosition} generalLoader={loader}/>}
            </div>
          </>
          :
          <div ref={filterRef}>
            <CollapsableDetailAgreement/>
            {role === '61b36e2fc34e22351bf8cfb4' && <TabDetailAgreements isResponsive={true} filterPosition={filterPosition} generalLoader={loader}/>}
          </div>
        }
      </ContainerScroll>
    </>
  )
}


export default AgreementDetail