import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Form, Grid, Progress } from "semantic-ui-react";
import modulo from "modulo-11";
import styled from "styled-components";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import InputFormTextAndNumber from "../InputAnimatedGeneralForms/InputFormTextAndNumber";
import InputFormDropdown from "../InputAnimatedGeneralForms/InputFormDropdown";
import InputDV from "../InputAnimatedGeneralForms/InputDV";
import {
  postCreateProviders,
  activeFormProvider,
} from "../../actions/providerActions";
import {
  refreshStateModule,
  warningFormModal,
} from "../../actions/modalActions";
import "./index.css";
import { getGeographicList } from "../../actions/defaultListActions";
import { FormContact } from "../FormContact/FormContact";
import { AttachmentButtonForm } from "../InputAnimatedGeneralForms/AttachmentButtonForm";
import useValidator from "../../helpers/simpleReactValidatorHook";
import { useNavigate } from "react-router-dom-v5-compat"

const Divider = styled.div`
  padding-left: 160px;
  padding-right: 160px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
    padding: 0px 15px;
  }
`;
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 350px;
    margin: 0;
  }
`;
const Title = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133f4f;
`;
const Title2 = styled.p`
  color: #133f4f;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding-top: 22px;
`;
const Title3 = styled.p`
  color: #133f4f;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
`;
const TextInformation = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
`;

const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  @media only screen and (max-width: 1200px) {
    display: block;
    margin-bottom: 30px;
  }
`;
const ButtoCancel = styled.button`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #22c1d0;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22c1d0;
  border-radius: 4px;
  background: #ffffff;
  margin-right: 8px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    float: left;
    width: 49%;
    margin: 0;
  }
`;
const ButtoSave = styled.button`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22c1d0;
  border-radius: 4px;
  background: #22c1d0;
  margin-left: 8px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    float: right;
    width: 49%;
    margin: 0;
  }
`;
const ColumnGrid = styled(Grid.Column)`
  width: 50% !important;
  @media only screen and (max-width: 1200px) {
    width: 100% !important;
  }
`;
const ColumnGridDividerOne = styled(Grid.Column)`
  width: 40% !important;
  @media only screen and (max-width: 1200px) {
    width: 80% !important;
  }
`;
const ColumnGridDividerTwo = styled(Grid.Column)`
  width: 10% !important;
  @media only screen and (max-width: 1200px) {
    width: 20% !important;
  }
`;
const TextNameFile2 = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 23px !important;
  color: #000000;
  font-size: 14px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 200px;
  margin: 0 0 0.25rem !important;
`;

const TextNameFile = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 23px !important;
  color: #000000;
  font-size: 14px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  margin: 0px !important;
`;
const ProgressBar = styled(Progress)`
  width: 100%;
  height: 3px;
  border-radius: 0px !important;
  margin: 0 0 0.5em !important;
  & > .bar {
    background-color: #009bdd !important;
  }
`;

const Container2 = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #c3132e;
  padding: 20px;
`;
const initialState = {
  providerCode: "",
  nameOfCompany: "",
  typeOfCompany: "",
  typePersonCompany: "",
  typeDocumentCompany: "",
  numberDocumentCompany: "",
  DV: "",
  phone: "",
  email: "",
  domain: "",
  address: "",
  countryList: [],
  selectedCountry: "",
  departmentList: [],
  selectedDepartment: "",
  cityList: [],
  selectedCity: "",
  legalManagerName: "",
  legalManagerLastName: "",
  typeDocumentLegalManager: "",
  numberDocumentLegalManager: "",
  status: false,
  errors: {},
  isChangedState: false,
  contactInformation: [
    {
      name: "",
      surNames: "",
      email: "",
      phoneContact: "",
      occupation: "",
    },
    {
      name: "",
      surNames: "",
      email: "",
      phoneContact: "",
      occupation: "",
    },
  ],
  supportFiles: [],
  progress: [0, 0, 0],
  errorFile: false,
  isVisible: false,
  supportDescription: "",
};
const optionsTypeOfCompany = [
  { key: "", text: "", value: "" },
  { key: "PUBLIC", text: "PÚBLICA", value: "PUBLIC" },
  { key: "PRIVATE", text: "PRIVADA", value: "PRIVATE" },
];

const optionsTypePersonCompany = [
  { key: "", text: "", value: "" },
  { key: "LEGAL_PERSON", text: "PERSONA JURÍDICA", value: "LEGAL_PERSON" },
];

const optionsTypeDocumentCompany = [
  { key: "", text: "", value: "" },
  { key: "NIT", text: "NIT", value: "NIT" },
  { key: "ID_EXTRANJERO", text: "ID EXTRANJERO", value: "ID_EXTRANJERO" },
];

const optionsTypeDocumentLegalManager = [
  { key: "", text: "", value: "" },
  { key: "C.C", text: "C.C", value: "C.C" },
  { key: "C.E", text: "C.E", value: "C.E" },
  { key: "DNI", text: "DNI", value: "DNI" },
  { key: "NIT", text: "NIT", value: "NIT" },
  { key: "NUI", text: "NUI", value: "NUI" },
  { key: "PAS", text: "PAS", value: "PAS" },
  { key: "PEP", text: "PEP", value: "PEP" },
  { key: "R.C", text: "R.C", value: "R.C" },
  { key: "T.I", text: "T.I", value: "T.I" },
];

const FormCreateProviders = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [state, setState] = useState({
    providerCode: "",
    nameOfCompany: "",
    typeOfCompany: "",
    typePersonCompany: "",
    typeDocumentCompany: "",
    numberDocumentCompany: "",
    DV: "",
    phone: "",
    email: "",
    domain: "",
    address: "",
    countryList: [],
    selectedCountry: "",
    departmentList: [],
    selectedDepartment: "",
    cityList: [],
    selectedCity: "",
    legalManagerName: "",
    legalManagerLastName: "",
    typeDocumentLegalManager: "",
    numberDocumentLegalManager: "",
    status: false,
    errors: {},
    isChangedState: false,
    contactInformation: [
      {
        name: "",
        surNames: "",
        email: "",
        phoneContact: "",
        occupation: "",
      },
      {
        name: "",
        surNames: "",
        email: "",
        phoneContact: "",
        occupation: "",
      },
    ],
    supportFiles: [],
    progress: [0, 0, 0],
    errorFile: false,
    isVisible: false,
    supportDescription: "",
  });
  const [messagesShown, setMessagesShown] = useState(false);
  const [formValidator, setShow, setFields] = useValidator(
    reactValidatorOptions
  );

  const { geographicList } = useSelector((state) => state.defaultListReducer);
  const  warningModal  = useSelector((state) => state.modalReducer.warningModal);
  const  messageModal  = useSelector((state) => state.modalReducer.messageModal);
  const { activeForm } = useSelector((state) => state.providerReducer);
  const moduleType = useSelector((state) => state.modalReducer.refreshModule);
  const errorsForm = useSelector(state=> state.errorReducer.errors)
  const errors = useSelector(state=> state.errorReducer.errorsForm)
  //   moduleType: state.modalReducer.refreshModule,

 /* const fetchGeographicList = () => {
    dispatch(getGeographicList()).then( ()=> {
     const dataCountry = geographicList.map((x) => {
       return { key: x.code, text: x.name, value: x.code }
     })
     dataCountry.unshift({ key: 'xxx', text: '', value: '' });
     setState((prevState) => ({ ...prevState, countryList: dataCountry }))
   })
 }
 
 useEffect(() => {
     fetchGeographicList()
 }, [])  
 */

  useEffect(() => {
    geographicList.forEach((data) => {
      if (data.code === state.selectedCountry) {
        const dataDepartments = data.departments.map((ele) => {
          return { key: ele.code, text: ele.name, value: ele.code };
        });
        dataDepartments?.unshift({ key: "x", text: "", value: "" });
        setState({
          ...state,
          departmentList: dataDepartments || [],
          selectedDepartment: "",
          selectedCity: "",
        });
      }
    });
  }, [state.selectedCountry]);

  useEffect(() => {
    geographicList.forEach((data) => {
      if (data.code === state.selectedCountry) {
        if (!!data.departments) {
          const dataDepartments = data.departments.forEach((data) => {
            if (data.code === state.selectedDepartment) {
              if (!!data.cities) {
                const dataCities = data.cities.map((ele) => {
                  return { key: ele.code, text: ele.name, value: ele.code };
                });
                dataCities?.unshift({ key: "xx", text: "", value: "" });
                setState({
                  ...state,
                  cityList: dataCities || [],
                  selectedCity: "",
                });
              }
            }
          });
        }
      }
    });
  }, [state.selectedDepartment]);

  useEffect(() => {
    if (activeForm === false && warningModal.status === true) {
      dispatch(activeFormProvider());
      setMessagesShown(true)
    }

    if (moduleType === true) {
      navigate(-1);
      dispatch(refreshStateModule());
    }
  }, [activeForm, warningModal.status, moduleType, dispatch, navigate]);


  useEffect(() => {
    setTimeout(() => {
      validateErrors()
    }, 500);
  }, [errors,errorsForm])
  
  

  useEffect(() => {
    if (messagesShown===true) {
      validateErrors();
    }
  }, [messagesShown]);

  const onChange = (e) => {
    const fieldName = e.target.id;
    const fieldlabel = e.target.getAttribute("label");
    let value = e.target.value; // Obtener el valor sin aplicar trim

    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const trimmedValue = trimStart(value);
    const diffLength = value.length - trimmedValue.length;

    const cursorPosition = Math.max(start - diffLength, 0);
    value = trimmedValue;
    setState((prevValues) => {
      const newValues = {...prevValues, [fieldName]: value }
      return newValues
    })
    const input = document.getElementById(fieldName);
    input.setSelectionRange(cursorPosition, cursorPosition);
    setFields(fieldName);
  };


const validateErrors = () => {
  const elementError= document.querySelector('.labelInputFormEntitiesError, .divInputDropdownFormEntitiesError, .supportFilesError, .spanLabelInputTopError')
  if (elementError !== null) {
    elementError.setAttribute('tabindex', '0');
    if(elementError.className.includes('divInputDropdownFormEntitiesError')){
      elementError.firstChild.focus()
    }else{
      elementError.firstChild.focus()
    }
  }
  setMessagesShown(false)
}

  const handleOnChange = (event, { value }) => {
    const name = event.target.id? event.target.id : event.currentTarget.parentNode.parentNode.id;
    setState({
      ...state,
      [name]: value,
    })
  }

  const trimStart = (input)=> {
    let start = 0;
    while (start < input.length && input[start] === ' ') {
        start++;
    }
    return input.substring(start);
  }

  const onEmailChange = (e) => {
    let inputValue = e.target.value;
    const fieldlabel = e.target.getAttribute("label");
    const inputId = e.target.id;

    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const trimmedValue = inputValue.trim();
    const diffLength = inputValue.length - trimmedValue.length;

    const cursorPosition = Math.max(start - diffLength, 0);
    inputValue = trimmedValue;

    setState((prevValues) => { 
      const newValues = {...prevValues, [inputId]: inputValue }
      return newValues
    })
    const input = document.getElementById(inputId)
    input.setSelectionRange(cursorPosition, cursorPosition)
    setFields(inputId)
  };

  const onBlurMail = (e) =>{
    setState({...state, 'email': state.email.toLowerCase() });
  }

  const onBlurDomain = (e) =>{
    setState({...state, 'domain': state.domain.toLowerCase() });
  }

  const handleCancel = () => {
    formValidator.hideMessages();
    //forceUpdate();
    dispatch(warningFormModal({ type: "warningLeaveProvider" }));
  };

  const validateDiferentContactInformation = (contactinfo) => {
    if (contactinfo !== undefined) {
      const contact1 = contactinfo[0]
      const contact2 = contactinfo[1]

      if (
        contact1.name.trim() !== contact2.name.trim() ||
        contact1.surNames.trim() !== contact2.surNames.trim() ||
        contact1.email.trim() !== contact2.email.trim() ||
        contact1.phoneContact.trim() !== contact2.phoneContact.trim() ||
        contact1.occupation.trim() !== contact2.occupation.trim()
      ) {
        return true;
      }

      return false;
    }
  }

  const calculateDV = () =>{
    // let generatedDV = ''
    // try {
    //   generatedDV = modulo.calc(state.numberDocumentCompany).toString()
    //   setState({...state, DV : generatedDV })
    // } catch (error) {

    // }

    let generatedDV = ''
    try {
      generatedDV = modulo.calc(state.numberDocumentCompany).toString()
      setState((prevValues) => { 
        const newValues = {...prevValues, DV: generatedDV }
        return newValues
      })
    } catch (error) {
      
    }
  }

  const makeSaveNotificationReq = () => {
    const {
      providerCode,
      nameOfCompany,
      typeOfCompany,
      typePersonCompany,
      typeDocumentCompany,
      numberDocumentCompany,
      DV,
      phone,
      email,
      domain,
      address,
      selectedCountry,
      selectedDepartment,
      selectedCity,
      legalManagerName,
      legalManagerLastName,
      typeDocumentLegalManager,
      numberDocumentLegalManager,
      status,
      supportFiles,
      contactInformation,
      supportDescription
    } = state;

      const body = {
        codeCompany: providerCode,
        name: nameOfCompany,
        providerDefinition: typeOfCompany,
        typePersonCompany: typePersonCompany,
        typeDocumentCompany: typeDocumentCompany,
        numberDocumentCompany: numberDocumentCompany,
        DV: "",
        phone: phone.length ===0 ? null :phone,
        email: email,
        domain: domain,
        address: address,
        country: selectedCountry,
        department: selectedDepartment,
        city: selectedCity,
        legalManagerName: legalManagerName,
        legalManagerLastName: legalManagerLastName,
        typeDocumentLegalManager: typeDocumentLegalManager,
        numberDocumentLegalManager: numberDocumentLegalManager,
        status: status,
        supportFiles:supportFiles,
        contactInformation:contactInformation,
        supportDescription:supportDescription
      };
      try {
        dispatch(postCreateProviders(body));
      } catch (error) {
        setMessagesShown(true)
      }
        
  };

  const handleSend = (e) => {
    e.preventDefault();
    if (formValidator.allValid()) {
      if (state.supportFiles.length > 0) {
        makeSaveNotificationReq()
      }else{
        setState((prevValues) => { 
          const newValues = {...prevValues, errorFile: true }
          return newValues
        })
        setMessagesShown(true)
      }
    } else {
      setShow(true)
      if (state.supportFiles.length ===0) {
        setState((prevValues) => { 
          const newValues = {...prevValues, errorFile: true }
          return newValues
        })
      }
      setMessagesShown(true)
    }
  };


  const loadNotes = async (number, size) => {
    var progress;
    for (let i = 10; i <= 100; i = i + 10) {
        await new Promise((resolve, reject) => {
            setTimeout(function () { resolve(); }, size);
        });
        progress = [...state.progress];
        progress[number] = i;
        setState({ ...state,progress: progress })
    }
}

//   const onChangeHandler = async (e) => {
//     const file = e.target.files
//     let fileInfo = {}
//     if (file.length + state.supportFiles.length <= 10) {
//             if (file.length) {
//                 for (let i = 0; i < file.length; i++) {
//                     fileInfo = {
//                         fileId: uuidv4(),
//                         base64: await fileToBase64Clean(file[i].name, file[i]),
//                         fileName: file[i].name,
//                         fileExtension: file[i].type.split("/").pop(),
//                     }
//                     if(fileInfo.fileExtension === "pdf"){
//                         setState({...state, supportFiles: [...state.supportFiles, fileInfo] })
//                         loadNotes(state.supportFiles.length - 1, file[i].size / 2500);

//                     }else{
//                         setState({...state, fileTypeError: true })
//                     setTimeout(() => {
//                         setState({ ...state,fileTypeError: false })
//                     }, 3000);
//                 }
//                 setState({ ...state,errorFile: false })
//             }
//         }
//     } else {
//         setState({...state, maxQuantityAchieved: true })
//         setTimeout(() => {
//             setState({ ...state,maxQuantityAchieved: false })
//         }, 3000);
//     }
// }

const setIsVisible = (isVisible) => {
  setState({...state,  isVisible: isVisible })
}

const setSupportFiles = (supportFiles) => {
  setState((prevValues) => { 
    const newValues = {...state, ['supportFiles']: supportFiles }
    return newValues
  })
}

const setErrorFile = (errorFile) => {
  setState((prevValues) => { 
  const newValues = {...prevValues, errorFile: errorFile }
  return newValues
  })
}

const setContactInformation= (index, property, value) => {
  const updatedContactInformation = [...state.contactInformation];
  updatedContactInformation[index][property] = value;
  setState((prevValues) => { 
    const newValues = {...prevValues, ['contactInformation']: updatedContactInformation }
    return newValues 
  })
  setFields(`${property}${index+1}`)
}

const setMessagesShownTwo =(value) => {
  setMessagesShown(value)
}

const addNewContactFields =()=>{
  setState((prevValues) => { 
    const newValues = {...prevValues, 
      contactInformation: [...values.contactInformation, {
        name:'',
        surNames:'',
        email:'',
        phoneContact:'',
        occupation:''
      }] 
    }
  return newValues
  })
}

const removeContactsFields = () =>{
  if (state.contactInformation.length >= 3) {
    const updatedContactInformation = state.contactInformation.slice(0, -1);
    setValues((prevValues) => { 
      const newValues = {...prevValues, contactInformation: updatedContactInformation }
      return newValues
    })
  }
}




  return (
    <Divider>
      <CustomForm>
        <Title>Datos del operador</Title>
        <Grid>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={"providerCode"}
              label={"Código de operador"}
              type={"text"}
              onChange={onChange}
              value={state.providerCode}
              validator={formValidator}
              validateOptions={["required", "providerClientCode"]}
              error={state.errors}
              setMessagesShown={setMessagesShown}
            />
          </ColumnGrid>
        </Grid>
        <Grid>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={"nameOfCompany"}
              label={"Nombre"}
              type={"text"}
              onChange={onChange}
              value={state.nameOfCompany}
              validator={formValidator}
              validateOptions={[
                "required",
                "nameOfCompany",
                { min: 3 },
                { max: 100 },
              ]}
              error={state.errors}
            />
          </ColumnGrid>
          <ColumnGrid>
            <InputFormDropdown
              options={optionsTypeOfCompany}
              placeholder={"Tipo de empresa"}
              value={state.typeOfCompany}
              handleOnChange={handleOnChange}
              id="typeOfCompany"
              validator={formValidator}
              validateOptions={"required"}
              error={state.errors}
            />
          </ColumnGrid>
        </Grid>
        <Grid>
          <ColumnGrid>
            <InputFormDropdown
              options={optionsTypePersonCompany}
              placeholder={"Tipo de persona"}
              value={state.typePersonCompany}
              handleOnChange={handleOnChange}
              id={"typePersonCompany"}
              validator={formValidator}
              validateOptions={"required"}
              error={state.errors}
            />
          </ColumnGrid>
          <ColumnGrid>
            <InputFormDropdown
              options={optionsTypeDocumentCompany}
              placeholder={"Tipo de documento"}
              value={state.typeDocumentCompany}
              handleOnChange={handleOnChange}
              id={"typeDocumentCompany"}
              validator={formValidator}
              validateOptions={"required"}
              error={state.errors}
            />
          </ColumnGrid>
        </Grid>
        <Grid>
          <ColumnGridDividerOne>
            <InputFormTextAndNumber
              id={"numberDocumentCompany"}
              label={"No. documento"}
              type={"text"}
              onChange={onChange}
              value={state.numberDocumentCompany}
              onBlur={calculateDV}
              validator={formValidator}
              validateOptions={["required", "numeric", { min: 8 }, { max: 12 }]}
              error={state.errors}
            />
             
          </ColumnGridDividerOne>
          <ColumnGridDividerTwo>
            <InputDV
              id={"DV"}
              label={"DV"}
              type={"text"}
              onChange={onChange}
              value={state.DV}
              validator={formValidator}
              disabled={true}
              validateOptions={
                state.typeDocumentCompany === "NIT"
                  ? ["required", "numeric", { min: 1 }, { max: 1 }]
                  : ["numeric", { min: 1 }, { max: 1 }]
              }
              error={state.errors}
            />
          </ColumnGridDividerTwo>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={"phone"}
              label={"Teléfono"}
              type={"text"}
              onChange={onChange}
              value={state.phone}
              validator={formValidator}
              validateOptions={['phones']}
              error={state.errors}
            />
          </ColumnGrid>
        </Grid>
        <Grid>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={"email"}
              label={"Email"}
              type={"text"}
              onChange={onEmailChange}
              value={state.email}
              validator={formValidator}
              validateOptions={["required", "email", { min: 6 }, { max: 200 }]}
              error={state.errors}
              onBlur={onBlurMail}
              setMessagesShown={setMessagesShown}
            />
          </ColumnGrid>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={"domain"}
              label={"Dominio"}
              type={"text"}
              onChange={onEmailChange}
              value={state.domain}
              validator={formValidator}
              validateOptions={"url"}
              error={state.errors}
              onBlur={onBlurDomain}
              setMessagesShown={setMessagesShownTwo}
            />
          </ColumnGrid>
        </Grid>
        <Grid>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={"address"}
              label={"Dirección"}
              type={"text"}
              onChange={onChange}
              value={state.address}
              validator={formValidator}
              validateOptions={["address", { min: 1 }, { max: 200 }]}
              error={state.errors}
            />
          </ColumnGrid>
          <ColumnGrid>
            <InputFormDropdown
              options={props.countryList}
              placeholder={"País"}
              value={state.selectedCountry}
              handleOnChange={handleOnChange}
              id={"selectedCountry"}
              validator={formValidator}
              validateOptions={"required"}
              error={state.errors}
            />
          </ColumnGrid>
          <ColumnGrid>
            <InputFormDropdown
              options={state.departmentList}
              placeholder={"Departamento"}
              value={state.selectedDepartment}
              handleOnChange={handleOnChange}
              id={"selectedDepartment"}
              validator={formValidator}
              validateOptions={"required"}
              error={state.errors}
            />
          </ColumnGrid>

          <ColumnGrid>
            <InputFormDropdown
              options={state.cityList}
              placeholder={"Ciudad"}
              value={state.selectedCity}
              handleOnChange={handleOnChange}
              id={"selectedCity"}
              validator={formValidator}
              validateOptions={"required"}
              error={state.errors}
            />
          </ColumnGrid>
        </Grid>
        <Title2>Datos representante legal</Title2>
        <Grid>
          <ColumnGrid>
            {
              <InputFormTextAndNumber
                id={"legalManagerName"}
                label={"Nombres representante legal"}
                type={"text"}
                onChange={onChange}
                value={state.legalManagerName}
                validator={formValidator}
                validateOptions={[
                  "required",
                  "nameOfProviderClient",
                  { min: 1 },
                  { max: 100 },
                ]}
                error={state.errors}
              />
            }
          </ColumnGrid>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={"legalManagerLastName"}
              label={"Apellidos representante legal"}
              type={"text"}
              onChange={onChange}
              value={state.legalManagerLastName}
              validator={formValidator}
              validateOptions={[
                "required",
                "nameOfProviderClient",
                { min: 1 },
                { max: 100 },
              ]}
              error={state.errors}
            />
          </ColumnGrid>
        </Grid>
        <Grid>
          <ColumnGrid>
            <InputFormDropdown
              options={optionsTypeDocumentLegalManager}
              placeholder={"Tipo de documento representante legal"}
              value={state.typeDocumentLegalManager}
              handleOnChange={handleOnChange}
              id={"typeDocumentLegalManager"}
              validator={formValidator}
              validateOptions={"required"}
              error={state.errors}
            />
          </ColumnGrid>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={"numberDocumentLegalManager"}
              label={"No. documento representante legal"}
              onChange={onChange}
              value={state.numberDocumentLegalManager}
              validator={formValidator}
              validateOptions={["required", "numeric", { min: 4 }, { max: 15 }]}
              error={state.errors}
            />
          </ColumnGrid>
        </Grid>

        <AttachmentButtonForm supportFiles ={state.supportFiles} setSupportFiles={setSupportFiles} setErrorFile={setErrorFile} formValidator={formValidator} errorFile={state.errorFile} supportDetail={state.supportDescription} onChange={onChange} validator={formValidator}/>

        <TextInformation>
          Para tener más información del operador, debes ingresar los siguientes
          datos de contacto.
        </TextInformation>

        <FormContact setContactInformation={setContactInformation} contactInformation={state.contactInformation} formValidator={formValidator} errors={state.errors} supportFiles={state.supportFiles} setSupportFiles={setSupportFiles} addNewContactFields={addNewContactFields} removeContactsFields = {removeContactsFields} errorFile={state.errorFile} setErrorFile={setErrorFile}/>

        <ContainerButtons>
          <ButtoCancel onClick={handleCancel}>Cancelar</ButtoCancel>
          <ButtoSave onClick={handleSend}>Guardar</ButtoSave>
        </ContainerButtons>
      </CustomForm>
    </Divider>
  );
};

export default FormCreateProviders;
