import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actionUpdateTabFilter } from '../../../../actions/filterDetailActions'
import ContainerEmployeeSearch from '../../../../components/Search/SearchDetailProvider/Containers/ContainerEmployeeSearch'
import Users from './Users'
import WarningModalUserAndEmployes from '../../../../components/FormUser/Modal/WarningModalUserAndEmployes';


const AllUsers = (props) => {

    const dispatch = useDispatch()
    const idProviders = sessionStorage.getItem("id")

    useEffect(() => {
        dispatch(actionUpdateTabFilter('users'))
    }, [dispatch])

    return (
        <>
            <ContainerEmployeeSearch  filterPosition={props.filterPosition}/>
            <WarningModalUserAndEmployes detailType={'PROVIDER'}/>
            <Users
                companySerialID={idProviders} 
            />
        </>
    )
}

AllUsers.propTypes = {
}

export default AllUsers 