import React from 'react'
import styled from 'styled-components'
import Butterflies from '../../assets/icons/login/butterflies.svg'
import { ReactComponent as Loader} from '../../assets/icons/login/loader.svg'
import { useSelector } from 'react-redux'
import './modal.css'

const Divider = styled.div`
  width: 448px !important;
  height: 440px;
  position: absolute;
  z-index: 999;
  top: 168px;
  left: 0;
  right: 0; 
  bottom: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #FFFFFF;
  margin: auto;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  @media only screen and (max-width: 1200px)  {
    top: 80px;
    height: 450px !important;
  }
  @media only screen and (max-width: 482px)  {
    width: 87.47% !important;
  }
`
const ContentButterflies = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${Butterflies});
  background-repeat: no-repeat;
  background-position-x: 100%;
  padding: 50px;
  position: absolute;
  vertical-align: middle;
`
const DividerContent = styled.div`  
  padding-top: 50px;
`

const ModalLoader = () => { 
  
  const {status, type} = useSelector((state) => state.modalReducer.messageLoginModal)

    return (
      <>
      {
        status === true && type === 'progress'
        && 
      <Divider>
        <ContentButterflies>
          <DividerContent>
            <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </DividerContent>
        </ContentButterflies>
      </Divider>
      }
      </>
    )
}
export default ModalLoader